










































































import AdminDashboardCharts from "@/components/Admin/Dashboard/Charts.vue";
import AdminDashboardStatistics from "@/components/Admin/Dashboard/Statistics.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import SharedDashboardCharts from "@/components/Shared/Dashboard/Charts.vue";
import vuexErrorHandler from "@/helpers/vuexErrorHandler";
import StatisticsService from "@/services/StatisticsService";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
    components: {
        AdminDashboardStatistics,
        AdminDashboardCharts,
        BaseWrapper,
        BaseCard,
        SharedDashboardCharts,
    },
    metaInfo() {
        const title = "Dashboard";

        return {
            title: title,
        };
    },
})
export default class AdminDashboard extends Vue {
    public selectedYear: number = new Date().getFullYear();
    public isLoading: boolean = false;
    public topCompanies: any[] = [];
    public dashboardData: any = {
        proposalsCount: 0,
        planFund: 0,
        usedFund: 0,
        funds: [],
    };

    get years(): any[] {
        let startYear: number = 2020;
        let currentYear: number = new Date().getFullYear();
        let years: any[] = [startYear];

        for (let i = 0; i < currentYear - startYear; i++) {
            years.push(startYear + i + 1);
        }

        return years.reverse();
    }

    get chartData() {
        let data: any[] = [
            {
                title: "Rencana Dana CSR",
                value: this.dashboardData.planFund,
            },
            {
                title: "Dana Yang Telah Diberikan",
                value: this.dashboardData.usedFund,
            },
        ];

        return data;
    }

    get dataStatistics() {
        let dashboard: any[] = [
            {
                title: "Proposal Masuk",
                icon: "mdi-clipboard-list",
                value: this.dashboardData.proposalsCount,
                color: "primary",
                suffix: "proposal",
            },
            {
                title: "Total Rencana Dana",
                icon: "mdi-bank",
                value: this.dashboardData.planFund,
                color: "error",
                type: "currency",
            },
            {
                title: "Total Dana Diberikan",
                icon: "mdi-bank-transfer-out",
                value: this.dashboardData.usedFund,
                color: "info",
                type: "currency",
            },
        ];

        return dashboard;
    }

    async getDashboardData(year?: number) {
        const response = await this.$store.dispatch(
            "statistics/getAdminDashboard",
            year
        );

        this.dashboardData = response.dashboard;

        return response.dashboard;
    }

    async getTopCompanies(year?: number) {
        const response = await StatisticsService.getTopCompanies(year).catch(
            (err: any) => vuexErrorHandler(err)
        );
        const { companies } = response.data;

        this.topCompanies = companies;

        return companies;
    }

    async getAllData() {
        this.isLoading = true;

        const dashboard = await this.getDashboardData(this.selectedYear);
        const companies = await this.getTopCompanies(this.selectedYear);

        // console.log("Dashboard:", dashboard);
        // console.log("Companies:", companies);

        this.isLoading = false;
    }

    @Watch("selectedYear", { immediate: true })
    updateAllData() {
        this.getAllData();
    }

    // async mounted() {
    //     await this.getAllData();
    // }
}
